import { DataSource } from '@alza/cms-components';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SurveyObjectTypeEnum } from 'app/common/enums';
import { CommonDataService, DialogsService, EnumsService, IEnumValue, MessageService, PagerService } from 'app/common/services';
import { FileUtils, ObjectUtils } from 'app/common/utils';
import { ISelectListItemDto } from 'app/models';
import { IRatingCollectionFilterDto, IRatingCollectionItemDto } from 'app/models/rating';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { RatingDataService } from '../rating-data.service';

interface IRatingCollectionFilterClientDto extends IRatingCollectionFilterDto {
    extendedSearch?: boolean;
}

@Component({
    selector: 'app-rating-collection',
    templateUrl: './rating-collection.component.html',
    styleUrls: ['./rating-collection.component.scss'],
    providers: [RatingDataService, PagerService]
})
export class RatingCollectionComponent implements OnInit {
    @Output() clickCallback = new EventEmitter<number>();
    @Input() objectTypes: Array<SurveyObjectTypeEnum>;
    private _unsubscribe = new Subject<void>();
    public countries: Array<ISelectListItemDto> = [];
    public languages: Array<ISelectListItemDto> = [];
    public objectTypeEnums: Array<IEnumValue>;
    public dataSource: DataSource<IRatingCollectionFilterClientDto, IRatingCollectionItemDto>;
    public appliedFilter: IRatingCollectionFilterDto;

    constructor(
        public pager: PagerService<any, any>,
        private readonly dialogs: DialogsService,
        private readonly dataService: RatingDataService,
        private readonly commonService: CommonDataService,
        private readonly enumService: EnumsService,
        private readonly http: HttpClient,
        private readonly messageService: MessageService,

        private readonly translate: TranslateService
    ) {
        this.dataSource = new DataSource<IRatingCollectionFilterClientDto, IRatingCollectionItemDto>({
            endpoint: (p) => this.http.get<any>('/api/rating', { params: p }),
            filter: {
                objectTypes: [SurveyObjectTypeEnum.None],
                objectType: null,
                sortKey: 0,
                sortDesc: true
            },
            name: 'rating',
            emitInitialReload: false
        });
    }

    ngOnInit() {
        this.dataSource.data$.subscribe(() => {
            this.appliedFilter = ObjectUtils.cloneDeep(<IRatingCollectionFilterDto>this.dataSource.filter);
            this.appliedFilter.sortDesc = this.dataSource.filter.sortDesc;
            this.appliedFilter.sortKey = this.dataSource.filter.sortKey;
        });

        this.commonService.selectList.getTranslationCountries().subscribe((countries) => {
            this.countries = countries;
        });

        if (this.objectTypes) {
            this.dataSource.options.filter.objectTypes = this.objectTypes;
            this.dataSource.filter.objectTypes = this.objectTypes;
            this.dataSource.reload();
        }

        this.enumService.getEnum('CodeBook+SurveyObjectTypeEnum').subscribe((enums) => {
            this.objectTypeEnums = enums.filter((enm) => this.objectTypes.some((objType) => objType === enm.value));
        });

        this.commonService.language.getLanguages().subscribe((res) => {
            this.languages = res.map((selectListItem) => ({ id: selectListItem.id, text: selectListItem.name }));
        });

        this.messageService
            .getMessage('Category_RatingExport')
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.export();
            });
    }

    public click(id: number) {
        this.clickCallback.emit(id);
    }

    public getEllipsisSubstr(value: string, length: number) {
        if (value && value.length > length) {
            return value.substring(0, length) + '...';
        } else {
            return value;
        }
    }

    public export() {
        this.dataService.getCollectionCsv(this.appliedFilter).subscribe(
            (result) => {
                if (result.status === 204) {
                    this.dialogs.warningMessage(this.translate.instant('Common_CsvEmpty'));
                    this.messageService.sendMessage('Category_Rating_Exported');
                    return;
                }
                const fileName = 'CategoryRating_' + moment(new Date()).format('YYYYMMDD_HHmm') + '.csv';
                FileUtils.downloadCsvFile(fileName, result.body);
                this.messageService.sendMessage('Category_Rating_Exported');
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                }
                this.messageService.sendMessage('Category_Rating_Exported');
            }
        );
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}
