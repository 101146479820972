import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-review-modal',
    templateUrl: './review-modal.component.html',
    styleUrls: ['./review-modal.component.scss']
})
export class ReviewModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public review: string) {}
}
