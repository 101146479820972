import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRatingCollectionFilterDto, IRatingLimitedCollectionFilterDto, IRatingLimitedCollectionItemDto } from 'app/models/rating';

@Injectable()
export class RatingDataService {
    private _apiRoute = '/api/rating';

    constructor(private http: HttpClient) {}

    public loadLimitedRatings(filter: IRatingLimitedCollectionFilterDto) {
        const params = filter || {};
        return this.http.get<Array<IRatingLimitedCollectionItemDto>>(this._apiRoute + '/limited', { params: params });
    }

    public getCollectionCsv(filter: Partial<IRatingCollectionFilterDto>) {
        return this.http.get(this._apiRoute + '/collection-csv', {
            params: this.parseFilter(filter),
            responseType: 'arraybuffer',
            observe: 'response'
        });
    }

    private parseFilter(filter: Partial<IRatingCollectionFilterDto>) {
        let params = new HttpParams();
        for (const [key, value] of Object.entries(filter)) {
            if (value === null || value === undefined) {
                continue;
            }
            if (Array.isArray(value)) {
                value.filter((x) => x !== null).forEach((x) => (params = params.append(key, ('' + x).toString())));
            } else {
                params = params.set(key, ('' + value).toString());
            }
        }
        return params;
    }
}
