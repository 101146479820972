import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { RatingCollectionComponent } from './rating-collection/rating-collection.component';
import { RatingDetailComponent } from './rating-detail/rating-detail.component';
import { ReviewModalComponent } from './review-modal/review-modal.component';

@NgModule({
    declarations: [RatingCollectionComponent, RatingDetailComponent, ReviewModalComponent],
    exports: [RatingCollectionComponent, RatingDetailComponent],
    imports: [ComponentsModule, TranslateModule]
})
export class RatingModule {}
