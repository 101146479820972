<form (ngSubmit)="dataSource.reload()">
    <div class="row">
        <cms-input
            name="searchValue"
            [id]="'searchValue'"
            [(ngModel)]="dataSource.filter.searchValue"
            [labelText]="'Common_Search_Heading' | translate"
            [placeholder]="'Common_SearchPlaceholder' | translate"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-3"
        >
            <label left-addon class="input-group-addon fa-primary-hover" for="searchValue"> <i class="fas fa-search"></i> </label>
        </cms-input>
        <cms-select
            [id]="'appcountryId'"
            [(ngModel)]="dataSource.filter.countryId"
            name="appcountryId"
            [items]="countries"
            bindLabel="text"
            bindValue="id"
            [labelText]="'Common_Country' | translate"
            [multiple]="false"
            clearable="true"
            placeholder="{{ 'Admin_Common_all' | translate }}"
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
        >
        </cms-select>
        <cms-select
            [id]="'applanguageId'"
            [(ngModel)]="dataSource.filter.languageId"
            name="applanguageId"
            [items]="languages"
            bindLabel="text"
            bindValue="id"
            [labelText]="'Common_Language' | translate"
            [multiple]="false"
            clearable="true"
            class="col-xs-12 col-md-4 col-lg-3 flexcol"
        >
        </cms-select>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 withoutHeading">
            <div class="form-group">
                <div class="btn-group">
                    <button type="submit" class="btnCollapsedSearch btn btn-primary" data-tid="btnSearchCollapsed" *ngIf="!dataSource.filter.extendedSearch">
                        {{ 'Common_Search_Button' | translate }}
                    </button>
                    <button
                        type="button"
                        class="btnCollapsedSearch btn btn-secondary"
                        (click)="dataSource.resetFilter()"
                        data-tid="btnClearSearchCollapsed"
                        *ngIf="!dataSource.filter.extendedSearch"
                    >
                        {{ 'Common_Search_Clear' | translate }}
                    </button>
                </div>
                <app-show-more-button
                    [isOpen]="dataSource.filter.extendedSearch"
                    (click)="dataSource.filter.extendedSearch = !dataSource.filter.extendedSearch"
                >
                </app-show-more-button>
            </div>
        </div>
    </div>
    <app-open-close-content [isOpen]="dataSource.filter.extendedSearch">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="actualVersionRatingFrom">{{ 'Rating_ActualVersionRating' | translate }}</label>
                    <div class="row">
                        <cms-input
                            name="actualVersionRatingFrom"
                            [id]="'actualVersionRatingFrom'"
                            placeholder="{{ 'Rating_From' | translate }}"
                            [(ngModel)]="dataSource.filter.actualVersionRatingFrom"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                        <cms-input
                            name="actualVersionRatingTo"
                            [id]="'actualVersionRatingTo'"
                            placeholder="{{ 'Rating_To' | translate }}"
                            [(ngModel)]="dataSource.filter.actualVersionRatingTo"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="actualVersionAnswerTotalCountFrom">{{ 'Rating_ActualVersionAnswersCount' | translate }}</label>
                    <div class="row">
                        <cms-input
                            name="actualVersionAnswerTotalCountFrom"
                            [id]="'actualVersionAnswerTotalCountFrom'"
                            placeholder="{{ 'Rating_From' | translate }}"
                            [(ngModel)]="dataSource.filter.actualVersionAnswerTotalCountFrom"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                        <cms-input
                            name="actualVersionAnswerTotalCountTo"
                            [id]="'actualVersionAnswerTotalCountTo'"
                            placeholder="{{ 'Rating_To' | translate }}"
                            [(ngModel)]="dataSource.filter.actualVersionAnswerTotalCountTo"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="differenceFrom">{{ 'Rating_Difference' | translate }}</label>
                    <div class="row">
                        <cms-input
                            name="differenceFrom"
                            [id]="'differenceFrom'"
                            placeholder="{{ 'Rating_From' | translate }}"
                            [(ngModel)]="dataSource.filter.differenceFrom"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                        <cms-input
                            name="differenceTo"
                            [id]="'differenceTo'"
                            placeholder="{{ 'Rating_To' | translate }}"
                            [(ngModel)]="dataSource.filter.differenceTo"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 withoutHeading">
                <cms-checkbox
                    [id]="'onlyWithReview'"
                    name="onlyWithReview"
                    labelText="{{ 'OnlyWithReview' | translate }}"
                    [(ngModel)]="dataSource.filter.onlyWithReview"
                ></cms-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="archiveVersionRatingFrom">{{ 'Rating_ArchiveVersionRating' | translate }}</label>
                    <div class="row">
                        <cms-input
                            name="archiveVersionRatingFrom"
                            [id]="'archiveVersionRatingFrom'"
                            placeholder="{{ 'Rating_From' | translate }}"
                            [(ngModel)]="dataSource.filter.archiveVersionRatingFrom"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                        <cms-input
                            name="archiveVersionRatingTo"
                            [id]="'archiveVersionRatingTo'"
                            placeholder="{{ 'Rating_To' | translate }}"
                            [(ngModel)]="dataSource.filter.archiveVersionRatingTo"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="archiveVersionAnswerTotalCountFrom">{{ 'Rating_ArchiveVersionAnswersCount' | translate }}</label>
                    <div class="row">
                        <cms-input
                            name="archiveVersionAnswerTotalCountFrom"
                            [id]="'archiveVersionAnswerTotalCountFrom'"
                            placeholder="{{ 'Rating_From' | translate }}"
                            [(ngModel)]="dataSource.filter.archiveVersionAnswerTotalCountFrom"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                        <cms-input
                            name="archiveVersionAnswerTotalCountTo"
                            [id]="'archiveVersionAnswerTotalCountTo'"
                            placeholder="{{ 'Rating_To' | translate }}"
                            [(ngModel)]="dataSource.filter.archiveVersionAnswerTotalCountTo"
                            type="number"
                            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        ></cms-input>
                    </div>
                </div>
            </div>
            <app-category-select-one
                [id]="'appRootCategories'"
                [(ngModel)]="dataSource.filter.rootCategoryId"
                name="appRootCategories"
                [labelText]="'Category_CollectionTitle' | translate"
                placeholder="{{ 'Admin_Common_all' | translate }}"
                class="col-sm-12 col-md-4 col-lg-3 flexcol prewrap"
                *ngIf="objectTypes.indexOf(3) > -1 || objectTypes.indexOf(4) > -1"
            >
            </app-category-select-one>
            <cms-select
                [id]="'appObjectType'"
                [(ngModel)]="dataSource.filter.objectType"
                name="appObjectType"
                [items]="objectTypeEnums"
                bindLabel="displayName"
                bindValue="value"
                [labelText]="'Category_ObjectType' | translate"
                placeholder="{{ 'Admin_Common_all' | translate }}"
                clearable="true"
                class="col-sm-12 col-md-4 col-lg-3 flexcol"
                *ngIf="objectTypes.indexOf(3) > -1 || objectTypes.indexOf(4) > -1"
            >
            </cms-select>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="form-group">
                    <div class="btn-group">
                        <button type="submit" class="btnExpandedSearch btn btn-primary" data-tid="btnSearchExpanded">
                            {{ 'Common_Search_Button' | translate }}
                        </button>
                        <button type="button" class="btnExpandedSearch btn btn-secondary" (click)="dataSource.resetFilter()" data-tid="btnClearSearchExpanded">
                            {{ 'Common_Search_Clear' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </app-open-close-content>
</form>

<cms-data-grid [id]="'rating'" [dataSource]="dataSource" *ngIf="dataSource" (rowClick)="click($event.objectId)">
    <cms-column size="minmax(100px, max-content)" [header]="'Common_Id' | translate" field="objectId" [sortIndex]="0"></cms-column>
    <cms-column [header]="'Rating_Name' | translate" field="objectName" [sortIndex]="1"></cms-column>
    <cms-column
        *ngIf="objectTypes.indexOf(3) > -1 || objectTypes.indexOf(4) > -1"
        size="minmax(100px, max-content)"
        [header]="'Category_ObjectTypes' | translate"
        field="objectType"
        [sortIndex]="6"
        type="custom"
        type="enum"
        enum="CodeBook+SurveyObjectTypeEnum"
    ></cms-column>
    <cms-column size="minmax(100px, max-content)" [header]="'Category_Language' | translate" field="languageName" [sortIndex]="2"></cms-column>
    <cms-column
        *ngIf="objectTypes.indexOf(1) > -1"
        size="minmax(100px, max-content)"
        [header]="'Rating_ResponsibleDepartment' | translate"
        field="articleResponsibleDepartment"
        [sortIndex]="7"
        type="enum"
        enum="Article+ArticleDepartment"
    ></cms-column>
    <cms-column
        *ngIf="objectTypes.indexOf(1) > -1"
        size="minmax(100px, max-content)"
        [header]="'Rating_ResponsiblePerson' | translate"
        field="articleResponsiblePerson"
        [sortIndex]="8"
    ></cms-column>
    <cms-column size="minmax(100px, max-content)" [header]="'Rating_ActualVersionRating' | translate" field="actualVersionRating" [sortIndex]="3"></cms-column>
    <cms-column
        size="minmax(100px, max-content)"
        [header]="'Rating_ArchiveVersionRating' | translate"
        field="archiveVersionRating"
        [sortIndex]="4"
    ></cms-column>
    <cms-column
        size="minmax(100px, max-content)"
        [header]="'Rating_Difference' | translate"
        field="difference"
        [sortIndex]="5"
        type="custom"
        [template]="colDiffference"
    ></cms-column>
    <cms-column size="1fr" [header]="'Rating_Review' | translate" field="review" [sortIndex]="9" type="custom" [template]="colReview"></cms-column>

    <ng-template let-item #colDiffference>
        <app-trend *ngIf="item.difference !== null" [difference]="item.difference"></app-trend> {{ item.difference }}
    </ng-template>
    <ng-template let-item #colReview>
        <span class="grid-overflow" [cmsTooltip]="item.review">
            {{ getEllipsisSubstr(item.review, 50) }}
        </span>
    </ng-template>
</cms-data-grid>
