<div class="col-sm-12">
    <div class="table-responsive">
        <table class="table table-striped table-bordered table-hover dataTable">
            <thead>
                <tr role="row">
                    <th>
                        <span class="colTitle">{{ 'Common_Language' | translate }}</span>
                    </th>
                    <th>
                        <span class="colTitle">{{ 'Rating_ActualVersionRating' | translate }}</span>
                    </th>
                    <th>
                        <span class="colTitle">{{ 'Rating_ArchiveVersionRating' | translate }}</span>
                    </th>
                    <th>
                        <span class="colTitle">{{ 'Rating_Difference' | translate }}</span>
                    </th>
                </tr>
            </thead>
            <tbody class="clickable">
                <tr role="row" *ngFor="let item of ratings; let ix = index" [attr.data-tid]="'row_' + ix">
                    <td>{{ item.languageName }}</td>
                    <td>{{ item.actualVersionRating }}</td>
                    <td>{{ item.archiveVersionRating }}</td>
                    <td>
                        <app-trend *ngIf="item.difference !== null" [difference]="item.difference"></app-trend>
                        {{ item.difference }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!ratings || ratings.length === 0" class="alert alert-info">
        <h4><i class="fas fa-info-circle"></i> {{ 'Pager_NoItems' | translate }}</h4>
    </div>
</div>

<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <h2>{{ 'Rating_ReviewData' | translate }}</h2>
    <br />
</div>

<div class="col-sm-12">
    <form (ngSubmit)="search()">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="row">
                    <cms-datetimepicker
                        [labelText]="'Rating_DateFrom' | translate"
                        [(ngModel)]="pager.filter.dateFrom"
                        class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        [id]="'dateFrom'"
                        name="dateFrom"
                    >
                    </cms-datetimepicker>
                    <cms-datetimepicker
                        [labelText]="'Rating_DateTo' | translate"
                        [(ngModel)]="pager.filter.dateTo"
                        class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                        [id]="'dateTo'"
                        name="dateTo"
                    >
                    </cms-datetimepicker>
                </div>
            </div>

            <cms-select
                [id]="'appVersions'"
                [(ngModel)]="pager.filter.version"
                name="appVersions"
                enum="Rating+Version"
                [labelText]="'Category_Version' | translate"
                [multiple]="false"
                clearable="true"
                placeholder="{{ 'Admin_Common_all' | translate }}"
                class="col-xs-12 col-md-4 col-lg-3 flexcol"
            >
            </cms-select>

            <cms-select
                [id]="'applanguageId'"
                [(ngModel)]="pager.filter.languageId"
                name="applanguageId"
                [items]="languages"
                bindLabel="name"
                bindValue="id"
                [labelText]="'Common_Language' | translate"
                [multiple]="false"
                clearable="true"
                class="col-xs-12 col-md-4 col-lg-3 flexcol"
            >
            </cms-select>

            <div class="col-sm-12 col-md-4 col-lg-4 withoutHeading">
                <div class="form-group">
                    <div class="btn-group">
                        <button type="submit" class="btnExpandedSearch btn btn-primary" data-tid="btnSearchExpanded">
                            {{ 'Common_Search_Button' | translate }}
                        </button>
                        <button type="button" class="btnExpandedSearch btn btn-secondary" (click)="pager.clearFilter()" data-tid="btnClearSearchExpanded">
                            {{ 'Common_Search_Clear' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row">
        <app-grid class="col-xs-12">
            <table class="table table-striped table-bordered table-hover dataTable">
                <thead>
                    <tr role="row">
                        <th>
                            <span class="colTitle">{{ 'Rating_Language' | translate }}</span>
                        </th>
                        <th>
                            <span class="colTitle">{{ 'Rating_Date' | translate }}</span>
                        </th>
                        <th>
                            <span class="colTitle">{{ 'Rating_Version' | translate }}</span>
                        </th>
                        <th>
                            <span class="colTitle">{{ 'Rating_Comment' | translate }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="clickable">
                    <tr role="row" *ngFor="let item of pager.items; let ix = index" [attr.data-tid]="'row_' + ix">
                        <td>
                            {{ item.languageName }}
                        </td>
                        <td>
                            <span class="text-navy">{{ item.dateInserted | moment : 'L' }} </span>
                            <small>{{ item.dateInserted | moment : 'LT' }}</small>
                        </td>
                        <td>{{ item.version | enum : 'Rating+Version' }}</td>
                        <td (click)="openReviewModal(item.review)">{{ getEllipsisSubstr(item.review, 100) }}</td>
                    </tr>
                </tbody>
            </table>
        </app-grid>
    </div>
</div>
