import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService, PagerService } from 'app/common/services';
import { SurveyObjectTypeEnum } from 'app/models/enums/code-book';
import { IRatingLimitedCollectionFilterDto, IRatingLimitedCollectionItemDto } from 'app/models/rating';
import { RatingDataService } from '../rating-data.service';
import { ReviewModalComponent } from '../review-modal/review-modal.component';

interface IReviewFilter {
    objectTypes: Array<SurveyObjectTypeEnum>;
    objectId: number;
    version?: number;
    dateFrom?: string;
    dateTo?: string;
    languageId?: number;
}

@Component({
    selector: 'app-rating-detail',
    templateUrl: './rating-detail.component.html',
    styleUrls: ['./rating-detail.component.scss'],
    providers: [RatingDataService, PagerService]
})
export class RatingDetailComponent implements OnInit {
    @Input() objectType: SurveyObjectTypeEnum;

    @Input() public set objectId(value: number) {
        this.ratingFilter.objectId = value;
        this.ratingFilter.objectType = this.objectType;

        this.dataService.loadLimitedRatings(this.ratingFilter).subscribe((res) => {
            this.ratings = res;
        });

        this.reviewFilter.objectTypes = [this.objectType];
        this.reviewFilter.objectId = value;
    }

    public ratingFilter: IRatingLimitedCollectionFilterDto = { objectType: 0, objectId: 0 };
    public ratings: Array<IRatingLimitedCollectionItemDto>;
    public reviewFilter: IReviewFilter = { objectTypes: [SurveyObjectTypeEnum.None], objectId: 0 };
    public languages: Array<ICountryTranslationDto> = [];
    public languagesDictionary: { [code: string]: string } = {};

    constructor(
        private dataService: RatingDataService,
        public pager: PagerService<any, any>,
        private readonly commonService: CommonDataService,
        private readonly dialogs: MatDialog
    ) {}

    ngOnInit() {
        this.commonService.language.getLanguages().subscribe((languages) => {
            this.languages = languages;
        });
        this.pager.initialize('api/rating/review', this.reviewFilter, { key: 0, desc: true });
    }

    public search() {
        this.dataService.loadLimitedRatings(this.ratingFilter).subscribe((res) => {
            this.ratings = res;
        });
        this.pager.search();
    }

    public getEllipsisSubstr(value: string, length: number) {
        if (value.length > length) {
            return value.substring(0, length) + '...';
        } else {
            return value;
        }
    }

    public openReviewModal(text: string) {
        this.dialogs.open<ReviewModalComponent>(ReviewModalComponent, { data: text, panelClass: 'dialog-md' });
    }
}
