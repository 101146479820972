<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'Rating_Review' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="text-justify">
        <p>{{ review }}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close type="button" class="btn btn-white" data-tid="closeModalPreview">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
